import Amplify, { Auth } from "aws-amplify";
import API from "@aws-amplify/api";
import dotenv from "dotenv";
dotenv.load();

const { 
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_IDENTITY_POOL_REGION,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN,
  REACT_APP_PAYMENT_API_ENDPOINT
 } = process.env;

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: REACT_APP_IDENTITY_POOL_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
    // userPoolWebClientId: "6m245250qei92kquq99lg76f5i",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //     domain: '.yourdomain.com',
    // // OPTIONAL - Cookie path
    //     path: '/',
    // // OPTIONAL - Cookie expiration in days
    //     expires: 365,
    // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //     sameSite: "strict" | "lax",
    // // OPTIONAL - Cookie secure flag
    // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //     secure: true
    // },

    // OPTIONAL - customized storage object
    //storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: "USER_PASSWORD_AUTH",

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    //clientMetadata: { myCustomKey: "myCustomValue" },

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: REACT_APP_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3001/",
      redirectSignOut: "http://localhost:3001/",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

// You can get the current config object
export const currentConfig = Auth.configure();

//API configration
const getToken = async () => {
  try {
    return (await Auth.currentSession()).idToken.jwtToken;
  } catch (e) {
    console.log("token>>>>>e", e);
    return null;
  }
};

const setCustomHeaders = async () => {
  const token = await getToken();
  let headers = {};
  if (token) {
    headers = {
      Authorization: token,
      // "Access-Control-Allow-Headers": "*",
      // "Access-Control-Request-Method" : "*",
      // "Access-Control-Allow-Origin" : "*"
    };
  }
  return headers;
};
API.configure({
  endpoints: [
    {
      name: "auctollo-payment-api",
      endpoint: REACT_APP_PAYMENT_API_ENDPOINT,
      region: "us-east-2",
      custom_header: async () => setCustomHeaders(),
    },
    {
      name:"auctollo-local-payment-api",
      endpoint:"http://0.0.0.0:8000/prod",
      region:"us-east-2",
      custom_header: async () => setCustomHeaders(),
    },
  ],
});
