import React from "react";

function Loading() {
  return (
    <div id="loading">
      <div className="load-circle">
        <img src="/assets/img/logo/gxs-logo-icon.png" alt="Loading..." />
      </div>
    </div>
  );
}

export default Loading;
