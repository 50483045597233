import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="container">
      <div className="mt-5">
        This page not reachable You can go to <Link to="/">Home</Link> page....
      </div>
    </div>
  );
}
