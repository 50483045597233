import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/header";
// import Footer from './components/footer';
import Home from "./components/home";
import PageNotFound from "./components/PageNotFound";
import UserList from "./components/user/UserList";
import UserView from "./components/user/UserDataView";
import Login from "./components/Login";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { createBrowserHistory } from "history";
import { Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./store/index";
import SubscriptionLogs from "./components/user/SubscriptionLogs";

const App = () => {
  const history = createBrowserHistory();
  return (
    <Provider store={store}>
      <Router basename="/" history={history} >
        <Header />
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/login" exact={true} component={Login} />
          <Route path="/users" exact={true} component={UserList} />
          <Route path="/user/subscriptionlogs" exact={true} component={SubscriptionLogs}/>
          <Route path="/user/:id" exact={true} component={UserView} />
          <Route path="/*" component={PageNotFound} />
          <Redirect from="*" to="/" />
        </Switch>
        {/* <Footer /> */}
      </Router>
    </Provider>
  );
};
export default App;
