import React, { useEffect } from "react";
import validateEmail from "../common/CheckEmailValidation";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import { currentConfig } from "./aws/AwsConfig";
import GetUserToken from "../common/GetUserToken";
import { Redirect } from "react-router-dom";
import Loading from "../common/Loading";
Amplify.configure(currentConfig);
export default function Login() {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [validation, setValidation] = React.useState({
    email: false,
    password: false,
    submit: false,
  });
  const [loginMessage, setLoginMessage] = React.useState({
    message: "",
    type: "",
  });
  const [redirectToPage, setRedirectToPage] = React.useState(
    GetUserToken() ? true : false
  );
  const [loading, setLoading] = React.useState(false);

  async function signIn() {
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      console.log('user',user)
      if (
        user &&
        user.username &&
        user.signInUserSession &&
        user.signInUserSession.accessToken &&
        user.signInUserSession.accessToken.jwtToken
      ) {
        let data = {
          userName: user.username,
          token: user.signInUserSession.accessToken.jwtToken,
        };
        if (
          user.signInUserSession.accessToken.payload &&
          user.signInUserSession.accessToken.payload["cognito:groups"] &&
          user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "Admin"
          )
        ) {
          localStorage.setItem("userData", JSON.stringify(data));
          setLoginMessage({
            message: "Login Successfully you will redirect in 2 sec",
            type: "success",
          });
          setTimeout(() => {
            setRedirectToPage(true);
          }, 100);
        } else {
          setLoginMessage({
            message: "You don't have access Please contact admin",
            type: "error",
          });
          setLoading(false);
        }
      }
    } catch (error) {
      if (error.message)
        setLoginMessage({ message: error.message, type: "error" });
      setLoading(false);
    }
  }
  const changeEmailInput = (e) => {
    if (validation.submit && !validation.email) {
      let data = { ...validation };
      if (e.target.value && validateEmail(e.target.value)) {
        data["email"] = true;
        setValidation(data);
      }
    }
    setEmail(e.target.value);
  };
  const changePasswordInput = (e) => {
    if (validation.submit && !validation.password) {
      let data = { ...validation };
      if (e.target.value) {
        data["password"] = true;
        setValidation(data);
      }
    }
    setPassword(e.target.value);
  };
  const onSubmit = () => {
    let data = { email: false, password: false, submit: true };

    if (email && validateEmail(email)) {
      data["email"] = true;
    }
    if (password) {
      data["password"] = true;
    }
    if (data.email && data.password) {
      signIn();
    }
    setValidation(data);
  };
  useEffect(() => {
    if (loginMessage && loginMessage.message) {
      setTimeout(() => {
        setLoginMessage({ message: "", type: "" });
      }, 10000);
    }
  }, [loginMessage]);
  return (
    <section className="intro site-form custom-section">
      {redirectToPage ? <Redirect to="/" /> : null}
      {loading ? <Loading /> : null}
      <div
        className="mask d-flex align-items-center"
        style={{ height: "calc(100vh- 56px)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card site-bg-white"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  {loginMessage.message ? (
                    <div
                      className={
                        loginMessage.type === "success"
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                      data-mdb-color={
                        loginMessage.type === "success" ? "success" : "danger"
                      }
                    >
                      {loginMessage.message}
                    </div>
                  ) : null}
                  <div className="pb-5">
                    <h1 className="fw-bold mb-5">Welcome</h1>
                    {/* <i className="fas fa-user-astronaut fa-3x my-4"></i> */}
                    <div className="md-form form-outline mb-4 ">
                      <input
                        type="email"
                        id="typeEmail"
                        className={
                          validation.submit && !validation.email
                            ? "form-control is-invalid form-control-lg"
                            : "form-control form-control-lg"
                        }
                        onChange={changeEmailInput}
                      />
                      <label
                        htmlFor="typeEmail"
                        className={"form-label " + (email ? "active" : "")}
                      >
                        Email
                      </label>
                      <div className="invalid-feedback">
                        {validation.submit && !validation.email
                          ? "Please provide a valid email"
                          : ""}
                      </div>
                    </div>

                    <div className="md-form form-outline mb-5">
                      <input
                        type="password"
                        id="typePassword"
                        className={
                          validation.submit && !validation.password
                            ? "form-control is-invalid form-control-lg"
                            : "form-control form-control-lg"
                        }
                        onChange={changePasswordInput}
                      />
                      <label
                        className={"form-label " + (password ? "active" : "")}
                        htmlFor="typePassword"
                      >
                        Password
                      </label>
                      <div className="invalid-feedback">
                        {validation.submit && !validation.password
                          ? "Please provide a valid password"
                          : ""}
                      </div>
                    </div>

                    <button
                      className="btn form-submit-button btn-lg btn-rounded gradient-custom px-5 br-10"
                      type="submit"
                      onClick={onSubmit}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
