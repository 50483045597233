import React, { useState } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdbreact";
import { Link, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GetUserToken from "../../common/GetUserToken";
import { Auth } from "aws-amplify";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [redirectToLogin, setRedirect] = useState(false);
  const location = useLocation();

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const logOut = async (e) => {
    try {
      await Auth.signOut();
      localStorage.clear();
      setRedirect(true);
      setToggle(!toggle);
    } catch (error) {
      console.log(error);
    }
  };

  const logIn = (e) => {
    setRedirect(false);
  };
  return (
    <React.Fragment key={toggle}>
      {redirectToLogin ? <Redirect to="/login" /> : ""}
      <MDBNavbar dark expand="md" className="custom-navbar site-bg-white">
        <MDBNavbarBrand>
          <Link to={"/users"} className="text-light">
            <img
              src="/assets/img/logo/logo.png"
              alt="Google XML sitemaps premium customers"
              width={150}
            />
          </Link>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={() => toggleCollapse} />
        <MDBNavbarNav right>
          {GetUserToken() ? (
            <Link
              to="/login"
              className="btn btn-sm m-0 waves-effect site-primary-colour-outline"
              onClick={(e) => logOut(e)}
            >
              Logout
            </Link>
          ) : null}
          {!location.pathname.includes("/login") && !GetUserToken() ? (
            <Link
              to="/login"
              className="btn btn-sm m-0 waves-effect site-primary-colour-outline"
              onClick={(e) => logIn(e)}
            >
              Login
            </Link>
          ) : null}
        </MDBNavbarNav>
      </MDBNavbar>
    </React.Fragment>
  );
}

export default Header;
