import InfoRounded from '@material-ui/icons/InfoRounded'

export const UsersViewColums = [
  {
    label: "No",
    field: "srNo",
    width: 100,
  },
  {
    label: "Name",
    field: "name",
    width: 100,
  },
  {
    label: "Email",
    field: "email",
    width: 100,
  },
  {
    label: "Registered At",
    field: "registered_at",
    sort: "disabled",
    width: 100,
  },
  
  {
    label:"domain",
    field:"domain",
    width:100
  },
  {
    label:"status",
    field:"status",
    sort:"disabled",
    width:100
  },
  {
    label:"Subscription Amount",
    field:"amount",
    sort:"disabled",
    width:200
  },
  {
    label:"Plan Name",
    field:"plan",
    width:200
  },
  // {
  //   label:"s3RootUrl",
  //   field:"s3RootUrl",
  //   sort:"disabled",
  //   width:100
  // },
  {
    label:"Subscription Id",
    field:"subscriptionId",
    sort:"disabled",
    width:100
  },
  {
    label: "Action",
    field: "button",
    sort: "disabled",
    width: 150,
  },
];
export const trasactionColumn = [
  {
    label: "Sr No",
    field: "srNo",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sr No",
    },
    width: 100,
  },
  {
    label: "Transaction",
    field: "id",
    width: 100,
  },
  {
    label: "Order Id",
    field: "orderId",
    width: 100,
  },
  {
    label: "Subscription Id",
    field: "subscriptionId",
    width: 100,
  },
  {
    label: "Retrieval Reference Number",
    field: "retrievalReferenceNumber",
    width: 100,
  },
  {
    label: "Plan Id",
    field: "planId",
    width: 100,
  },
  {
    label: "Billing Period Start-Date",
    field: "billingPeriodStartDate",
    width: 100,
  },
  {
    label: "Billing Period End-Date",
    field: "billingPeriodEndDate",
    width: 100,
  },
  {
    label: "Payment Method Token",
    field: "payment_method_token",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "View More",
    field: "button",
    sort: "disabled",
    width: 150,
  },
];
export const subscriptionLogColumn = [
  {
    label: "Sr No",
    field: "srNo",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sr No",
    },
    width: 100,
  },
  {
    headerName: "Created At",
    field: "invokeAt",
    width: 225,
  },
  {
    headerName: "Subscription Event",
    field: "type",
    width: 300,
  },
  {
    headerName: "Dispute Reason (if any)",
    field: "reason",
    width: 200,
  },
  {
    headerName: "Dispute Status(if any)",
    field: "status",
    width: 200,
  },
  {
    headerName: "Amout Disputed",
    field: "amountDisputed",
    width: 200,
  },
  // {
  //   label: "Transaction",
  //   field: "button",
  //   sort: "disabled",
  //   width: 150,
  // }
  {
		label: "Transaction",
		field: "Transaction",
		// sort: "disabled",
		width: 150,
		renderCell: ()=>{ return<InfoRounded
			fas="true"
			icon="info-circle"
		  />}
		
	  }
]
export const subscriptionColumn = [
  {
    label: "Sr No",
    field: "srNo",
    attributes: {
      "aria-controls": "DataTable",
      "aria-label": "Sr No",
    },
    width: 100,
  },
  {
    label: "Next Billing Date",
    field: "nextBillingDate",
    width: 100,
  },
  {
    label: "Created At",
    field: "createdAt",
    width: 100,
  },
  {
    label: "Updated At",
    field: "updatedAt",
    width: 100,
  },
  {
    label: "Billing Period Start Date",
    field: "billingPeriodStartDate",
    width: 100,
  },
  {
    label: "Billing Period End Date",
    field: "billingPeriodEndDate",
    width: 100,
  },
  {
    label: "Trial Duration Unit",
    field: "trialDurationUnit",
    width: 100,
  },
  {
    label: "Trail Duration",
    field: "trialDuration",
    width: 100,
  },
  {
    label: "Current Billing Cycle",
    field: "currentBillingCycle",
    width: 100,
  },
  {
    label: "Balance",
    field: "balance",
    width: 100,
  },
  {
    label: "Discounts",
    field: "discounts",
    width: 100,
  },
  {
    label: "Payment Method Token",
    field: "paymentMethodToken",
    width: 100,
  },
  {
    label: "Number Of Billing Cycles",
    field: "numberOfBillingCycles",
    width: 100,
  },
  {
    label: "Price",
    field: "price",
    width: 100,
  },
  {
    label: "Plan Id",
    field: "planId",
    width: 100,
  },
  {
    label: "Add ons",
    field: "addOns",
    width: 100,
  },
  {
    label: "Subscription Id",
    field: "id",
    width: 100,
  },
  {
    label: "Paid Through Date",
    field: "paidThroughDate",
    width: 100,
  },
  {
    label: "First Billing Date",
    field: "firstBillingDate",
    width: 100,
  },
  {
    label: "Billing day of Month",
    field: "billingDayOfMonth",
    width: 100,
  },
  {
    label: "Next Billing Period Amount",
    field: "nextBillingPeriodAmount",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    width: 100,
  },
  {
    label: "Merchant AccountId",
    field: "merchantAccountId",
    width: 100,
  },
  {
    label: "Failure Count",
    field: "failureCount",
    width: 100,
  },
  {
    label: "Trial Period",
    field: "trialPeriod",
    width: 100,
  },
  {
    label: "View More",
    field: "button",
    sort: "disabled",
    width: 150,
  },
];
export const transactionHistoryColumn = [
  {
    headerName: "Transaction Date",
    field: "transactionDate",
    width: 300,
  },
  {
    headerName: "Type",
    field: "type",
    width: 200,
  },
  {
    headerName: "Status",
    field: "status",
    width: 200,
  },
  {
    headerName: "ID",
    field: "tid",
    width: 200,
  },
  {
    headerName: "Current Billing Period",
    field: "currentBillingPeriod",
    width: 200,
  },
  {
    headerName: "Amount",
    field: "amount",
    width: 200,
  }
]
export const subscriptionHistoryColumn=[
  {
    headerName: "Time Stamp",
    field: "timestamp",
    width: 300,
  },
  {
    headerName: "Plan Name",
    field: "planName",
    width: 200,
  },
  {
    headerName: "Status",
    field: "status",
    width: 200,
  },
  {
    headerName: "Subscription Price",
    field: "subscriptionPrice",
    width: 200,
  },
  {
    headerName: "Balance",
    field: "balance",
    width: 200,
  },
  {
    headerName: "Failure Count",
    field: "failureCount",
    width: 200,
  },
  {
    headerName:"Current Billing Cycle",
    field:"currentBillingCycle",
    width:200,
  },
  {
    headerName:"User",
    field:"user",
    width:200
  }

]
// export const UserDetailColums = [
//   {
//     label: "Sr No",
//     field: "srNo",
//     attributes: {
//       "aria-controls": "DataTable",
//       "aria-label": "Sr No",
//     },
//     width: 100,
//   },
//   {
//     label: "Domain",
//     field: "url",
//     width: 100,
//   },
//   {
//     label: "Payment",
//     field: "payment_made",
//     width: 100,
//   },
//   {
//     label: "Sitemap URL",
//     field: "sitemapURL",
//     width: 100,
//   },
//   {
//     label: "Sitemap Date",
//     field: "sitemap_last_generated_date_time",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Payment Method",
//     field: "payment_method",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Subscription Id",
//     field: "subscription_id",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Trial Period",
//     field: "trial_period",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Billing Period Start-Date",
//     field: "billingPeriodStartDate",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Billing Period End-Date",
//     field: "billingPeriodEndDate",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Customer Website",
//     field: "customer_website",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Phone",
//     field: "phone",
//     sort: "disabled",
//     width: 100,
//   },
//   {
//     label: "Payment Method Token",
//     field: "payment_method_token",
//     sort: "disabled",
//     width: 100,
//   },
// ];
