import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles/";
import Paper from "@material-ui/core/Paper";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datepicker-style.module.css";
import arrow from "../../assets/img/arrow.svg";
import moment from "moment";
import { currentConfig } from "../aws/AwsConfig";
import Amplify from "aws-amplify";
import API from "@aws-amplify/api";
import GetUserToken from "../../common/GetUserToken";
import GetTableData from "../../common/GetTableData";
import Loading from "../../common/Loading";
import { subscriptionLogColumn,transactionHistoryColumn, subscriptionHistoryColumn } from "../../constant/TableColums";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

let treeCallCount = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  content: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));
Amplify.configure(currentConfig);
function SubscriptionLogs(props) {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [SubscriptionData, setSubscriptionData] = React.useState([]);
  const [translationData, setTanslationData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [tableAllData, setTableData] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(90, "days")
  );
  const [userSubscriptionData, setUserSubscriptionData] = React.useState(props.location.state.userDetails.domainItem.additionalData.subscriptionData)
  const [transactionHistory,setTransactionHistory]=React.useState()
  const [subscriptionHistory, setSubscriptionHistory] = React.useState()
  const tableData = useSelector((state) => state.reducer.subscriptionLogs);
  const classes = useStyles();
  const dispatch = useDispatch();
  const invokeType = [
    "subscription_charged_successfully",
    "subscription_charged_unsuccessfully",
    "dispute_lost",
    "subscription_went_active",
    "subscription_went_past_due",
    "subscription_canceled",
  ];
  React.useEffect(()=>{
    let rows = []
    let thistory= {}
      userSubscriptionData.map((dataItem,i)=>
          rows.push({
            id:i,
            transactionDate: new Date(dataItem.transactions[0].createdAt).toUTCString(),
            type:dataItem.transactions[0].type,
            status:dataItem.transactions[0].status,
            tid:dataItem.transactions[0].id,
            currentBillingPeriod: dataItem.firstBillingDate + ' to ' + new Date(new Date(dataItem.firstBillingDate).setMonth(new Date(dataItem.firstBillingDate).getMonth()+11)).toISOString().split('T')[0],
            paymentMethodToken:dataItem.paymentMethodToken,
            amount:'$'+dataItem.transactions[0].amount,
          })
        )
      thistory["columns"] = transactionHistoryColumn
      thistory["rows"] =rows
      setTransactionHistory(thistory)

    let srows=[]
    let shistory={}
    userSubscriptionData.map((dataItem,i)=>
      
      srows.push({
        id:i,
      timestamp: new Date(dataItem.statusHistory[0].timestamp).toUTCString(),
      planName:dataItem.planId,
      status:dataItem.status,
      subscriptionPrice: '$'+ dataItem.price,
      balance: '$'+dataItem.balance,
      failureCount:dataItem.failureCount,
      currentBillingCycle: dataItem.currentBillingCycle,
      user:dataItem.statusHistory[0].user
      }))
      shistory["columns"] = subscriptionHistoryColumn
      shistory["rows"] = srows
      setSubscriptionHistory(shistory)
  },[])
  const [selectedType, setSelectedType] = React.useState("");
  const [endDate, setEndDate] = React.useState(moment());
  const hide = () => {
    setModalOpen(false);
    setTanslationData({});
  };
  const reset = () => {

    const config = {
      body:{
        domain : props.location.state.domain,
        reset:true
      }
    }
    getApiData(config)
    setSearchValue('')
    setSelectedType('')
    setStartDate(moment().subtract(90, "days"))
    setEndDate(moment())
  };
  const handleChange = (e) => {
    setSelectedType(e.target.value);
  };
  const showTransitionModal = (data) => {
    setModalOpen(!isModalOpen);

    if (Object.keys(data).length && Object.keys(data[0]).length) {
      setTanslationData([data[0]]);
    } else {
      setTanslationData([]);
    }
  };
  const cellClick = (data) => {
    let transactionDetails = [];
    if (data.row.dispute) {
      transactionDetails = [data.row.transaction];
    } else {
      transactionDetails = data.row.transaction;
    }
    // let transactionDetails = data.row.transaction
    showTransitionModal(transactionDetails);
  };
  const getApiData = async(config)=>{
    let rows = [];
    let finalDataForTable = {};
    const response = await API.post(
      "auctollo-payment-api",
      "/payment/filterLogsData",
      config
    );
    let responseData = response.data;
    let responseArray = [];
    responseData.map((dataItem, index) => {
      responseArray.push(dataItem);
    });
    responseArray.map((dataItem, index) => {
      let sdata = dataItem?.dispute || {};
      let tdata = dataItem?.dispute?.transaction || {};
      let transactionDetails = dataItem?.transaction || [tdata];
      dataItem.invokeAt = new Date(dataItem.invokeAt).toDateString(); // moment(dataItem.invokeAt).format("DD-MM-YYYY HH:MM:SS")
      let infoIcon = (
        <InfoRounded
          key={index}
          fas
          icon="info-circle"
          onClick={() => {
            showTransitionModal(transactionDetails);
          }}
        />
      );
      const arrayItem = {
        ...dataItem,
        ...sdata,
        srNo: index + 1,
        id: index,
        button: infoIcon,

      }
      rows.push(arrayItem)
    });
    finalDataForTable["columns"] = subscriptionLogColumn;

    finalDataForTable["rows"] = rows;
    dispatch({ type: "ADD_SUBSCRIPTION_LOGS", data: finalDataForTable });
  }
  React.useEffect(() => {

    const tempTableData = Object.assign({}, tableAllData);

    if (tempTableData?.rows) {
      // setLoading(true);
      let config = {
        body:{
          domain:props.location.state.domain,
          startDate,
          endDate,
          eventType:selectedType,
          searchString:searchValue
        }
      }
      getApiData(config)
      
     
      
    }
  }, [startDate, endDate, tableAllData]);
  React.useEffect(()=>{
    const tempTableData = Object.assign({}, tableAllData);

    if (tempTableData?.rows) {
      let config = {
        body:{
          domain:props.location.state.domain,
          startDate,
          endDate,
          eventType:selectedType,
          searchString:searchValue

        }
      }
      if (searchValue !== "") {
        config.body.searchString = searchValue
        getApiData(config)
      }
    }

  },[searchValue])
  React.useEffect(()=>{
    const tempTableData = Object.assign({}, tableAllData);

    if (tempTableData?.rows) {
      let config = {
        body:{
          domain:props.location.state.domain,
          startDate,
          endDate
        }
      }
      if (selectedType !== "") {
        config.body.eventType=selectedType
        getApiData(config)
      }
    }
  },[selectedType])
  React.useEffect(() => {
    let dataFromLocalStorage = GetTableData();
    setLoading(true);
    const getDataApi = async () => {
      const config = {
        body: { domain: props.location.state.domain },
      };
      const response = await API.post(
        "auctollo-payment-api",
        "/payment/subscriptionLogs",
        config
      );
      let responseData = response.data;
      let responseArray = [];
      responseData.map((dataItem, index) => {
        responseArray.push(dataItem);
      });
      // console.log('init',responseData)
      setSubscriptionData(responseArray);
      setLoading(false);
    };
    if (GetUserToken() && dataFromLocalStorage) {
      getDataApi();
    }
  }, []);
  const onSelectStartDate = (startdate) => {
    setStartDate(startdate);
  };
  const onSelectEndDate = (enddate) => {
    setEndDate(enddate);
  };
  React.useEffect(() => {
    let rows = [];
    let finalDataForTable = {};

    SubscriptionData.map((dataItem, index) => {
      let sdata = dataItem.dispute || {};
      let tdata = dataItem.dispute?.transaction || {};
      let transactionDetails = dataItem.transaction || [tdata];
      dataItem.invokeAt = new Date(dataItem.invokeAt).toDateString(); // moment(dataItem.invokeAt).format("DD-MM-YYYY HH:MM:SS")
      let infoIcon = (
        <InfoRounded
          key={index}
          fas
          icon="info-circle"
          onClick={() => {
            showTransitionModal(transactionDetails);
          }}
        />
      );
      rows.push({
        srNo: index + 1,
        id: index,
        ...dataItem,
        ...sdata,
        button: infoIcon,
      });
    });
    finalDataForTable["columns"] = subscriptionLogColumn;

    finalDataForTable["rows"] = rows;
    setTableData(finalDataForTable);
    dispatch({ type: "ADD_SUBSCRIPTION_LOGS", data: finalDataForTable });
  }, [SubscriptionData]);
  return (
    <div>
      {loading ? <Loading /> : null}
      <div className="table-wrapper-scroll-y my-custom-scrollbar">
        {SubscriptionData && Object.keys(SubscriptionData).length ? (
          <MDBContainer>
            <MDBCard
              style={{ width: "100%", marginTop: "5rem" }}
              className="site-bg-white"
            >
              <MDBCardHeader color="site-primary-bg-colour" tag="h3">
                Subscription Log
              </MDBCardHeader>
              {/* {tableData.rows.length} */}
              <MDBCardBody>
                <MDBCardText>
                  <span className="font-weight-bold site-medium-text-color">
                    Email :{" "}
                  </span>
                  {props.location.state.userDetails.email}
                  <br></br>
                  <span className="font-weight-bold site-medium-text-color">
                    Registered At :{" "}
                  </span>
                  {props.location.state.userDetails.registered_at}
                  <br></br>
                  <span className="font-weight-bold site-medium-text-color">
                    Domain:{" "}
                  </span>
                  {props.location.state.domain}
                  <br></br>
                  <span className="font-weight-bold site-medium-text-color">

                    Name:{" "}
                  </span>
                  {props.location.state.userDetails.name}
                  <br></br>
                  
                  <span className="font-weight-bold site-medium-text-color">
                    Status :{" "}
                  </span>
                  {props.location.state.userDetails.domainItem.status}
                  <br />
                  <br></br>
                  <h4>
                  <b>

                  <span className="font-weight-bold site-medium-text-color">
                    Subscription ID :{" "}
                  </span>
                  {props.location.state.userDetails.domainItem.subscription_id}
                  </b>
                  </h4>
                  <div style={{marginLeft:"2%"}}>

                <tbody >
                  <br/>
                  <tr>
                  <td colSpan={6}><b><h5>Customer Details</h5></b></td>
                  </tr>
                  <hr/>
                  <tr>
                    {printObject(userSubscriptionData[0].transactions[0].customer)}
                  </tr>

                  <br/>
                  <tr>
                    <td colSpan={6}><b><h5>Subscription Details</h5></b></td>
                    
                  </tr>
                  <hr/>
                  <tr>
                    { 
                    
                    printObject({
                        subscriptionId : props.location.state.userDetails.domainItem.subscription_id,
                        planName : props.location.state.userDetails.domainItem.plan,
                        status: props.location.state.userDetails.domainItem.status,
                        subscriptionPrice: '$' + props.location.state.userDetails.domainItem.amount_paid,
                        billingPeriodEndDate:props.location.state.userDetails.domainItem.billingPeriodEndDate,
                        billingPeriodStartDate : props.location.state.userDetails.domainItem.billingPeriodStartDate
                      })
                    }
                  </tr>
                  <br/>
                  <tr>
                    <td colSpan={6}><b><h5> Billing Details</h5></b></td>
                  </tr>
                  <hr/>
                  <tr>
                    {
                      printObject({
                        firstBillingDate:userSubscriptionData[0].firstBillingDate,
                        currentBillingPeriod: userSubscriptionData[0].firstBillingDate + ' to ' + new Date(new Date(userSubscriptionData[0].firstBillingDate).setMonth(new Date(userSubscriptionData[0].firstBillingDate).getMonth()+11)).toISOString().split('T')[0],
                        paidThroughDate:userSubscriptionData[0].paidThroughDate,
                        balance: '$' + userSubscriptionData[0].balance,
                        nextBillingDate: userSubscriptionData[0].nextBillingDate,
                        nextBillAmount: '$' + userSubscriptionData[0].nextBillAmount,
                        numberOfBillingCycles: userSubscriptionData[0].numberOfBillingCycles
                      })
                    }
                  </tr>
                </tbody>
                </div>
                <br/>
                <div style={{marginLeft:"2%"}}>

                  <tr>
                    <td colSpan={6}><b><h5>Subsciption History </h5></b></td>
                  </tr>
                  </div>
                <div style={{ height: 250, width: "93%", marginLeft:"2%"}}>
                    <DataGrid
                      rows={subscriptionHistory.rows}
                      columns={subscriptionHistory.columns}
                      // pagination={false}
                      hideFooter
                      hideFooterPagination
                    />
                  </div>
                <br/>
                <div style={{marginLeft:"2%"}}>

                  <tr>
                    <td colSpan={6}><b><h5> Transactions </h5></b></td>
                  </tr>
                  </div>
                <div style={{ height: 250, width: "93%", marginLeft:"2%" }}>
                    <DataGrid
                      rows={transactionHistory.rows}
                      columns={transactionHistory.columns}
                      // pagination={false}
                      hideFooter
                      hideFooterPagination
                    />
                  </div>
                  

                </MDBCardText><br/>
                <div style={{marginLeft:"2%"}}>

                  <h4>Transaction Logs</h4>
                  </div>
                <Paper className={classes.content}>
                  <Typography variant="h6"> Filter by </Typography>
                  <div className={`${styles["datepickerWrapper"]}`}>
                    <Typography variant="subtitle1">Date range </Typography> :
                    &nbsp;&nbsp;
                    <div className={`${styles["datewrapper"]}`}>
                      <DatePicker
                        onChange={(date) => onSelectStartDate(date)}
                        openToDate={new Date(startDate)}
                        value={moment(startDate).format("MMM DD, YYYY")}
                        startDate={startDate}
                        endDate={endDate}
                        // calendarIcon="Calendar"
                        // selectsStart
                        dateFormat="MMM DD, YYYY"
                        dropdownMode="select"
                        adjustDateOnChange
                        className={`${styles["searchinput"]}`}
                      />
                    </div>
                    <div className={`${styles["arrow"]}`}>
                      <img src={arrow} alt="" />
                    </div>
                    <div className={`${styles["datewrapper"]}`}>
                      <DatePicker
                        onChange={(date) => onSelectEndDate(date)}
                        // onSelect={date=>this.onSelectEndDate(date)}
                        openToDate={new Date(endDate)}
                        value={moment(endDate).format("MMM DD, YYYY")}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        calendarIcon
                        // selectsEnd
                        dateFormat="MMM DD, YYYY"
                        dropdownMode="select"
                        adjustDateOnChange
                        className={`${styles["searchinput"]}`}
                      />
                    </div>
                    {/* </div>
                  <div className={`${styles["datepickerWrapper"]}`}> */}
                    &nbsp;&nbsp;
                    <Typography variant="subtitle1">
                      Subscription Event
                    </Typography>{" "}
                    : &nbsp;&nbsp;
                    <div className={`${styles["datewrapper"]}`}>
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          minWidth: 250,
                          marginBottom: "20px",
                          marginLeft: "20px",
                        }}
                      >
                        {/* <InputLabel id="demo-simple-select-standard-label">
                          Subscription Event
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectedType}
                          onChange={handleChange}
                          // label="Subscription Event"
                        >
                          {invokeType.map((item, i) => (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles["datewrapper"]}`}>
                      <div className={`${styles["searchbox"]}`}>
                        <TextField
                          label="Search"
                          value={searchValue}
                          onChange={(event) =>
                            setSearchValue(event.target.value)
                          }
                          size="small"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={`${styles["datepickerWrapper"]}`}>
                    <Button
                      variant="text"
                      className={`${styles["resetbutton"]}`}
                      onClick={reset}
                      size="large"
                    >
                      Reset
                    </Button>
                  </div>
                  <div className={classes.toolbar}></div>
                  <div style={{ height: 500, width: "100%" }}>
                    <DataGrid
                      rows={tableData.rows}
                      columns={tableData.columns}
                      checkboxSelection
                      disableSelectionOnClick
                      onCellClick={(data) => {
                        cellClick(data);
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </div>
                </Paper>

                {/* {SubscriptionData && Object.keys(SubscriptionData).length ? ( */}

                {/* ) : null} */}
              </MDBCardBody>
            </MDBCard>
          </MDBContainer>
        ) : null}
      </div>
      <div className="table-wrapper-scroll-y my-custom-scrollbar"></div>
      <div className="table-wrapper-scroll-y my-custom-scrollbar">
        <Paper
          sx={{ width: "100%", overflow: "hidden" }}
          className={classes.content}
        >
          <Rodal
            visible={isModalOpen}
            onClose={hide}
            width={1000}
            height={550}
            className={`${styles["uniqueName details-modal"]}`}
          >
            <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  {Object.keys(translationData).length ? (
                    <TableRow
                      sx={{ border: "red" }}
                      className={`${styles["details-modal-title"]}`}
                    >
                      <TableCell style={{ width: "300px" }}>
                        <Typography variant="h6">Transaction ID {" : "}
                          {translationData[0].id}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableHead>
                <tbody>
                  <Tree tdata={translationData[0]} />
                </tbody>
              </Table>
            </TableContainer>
          </Rodal>
        </Paper>
      </div>
    </div>
  );
}
const Tree = ({ tdata }) => {
  let data = {}
  // data.billing = tdata?.billing;
  data.creditCard = tdata?.creditCard
  // data.customer = tdata?.customer
  data.merchantAddress = tdata?.merchantAddress
  data.paymentReciept = tdata?.paymentReciept
  // data.subscription = tdata?.subscription
  data.planId = tdata?.planId
  return (
    <>
      {data !== null &&
        typeof data !== "undefined" &&
        Object.keys(data).length > 0 &&
        Object.keys(data).map((key, i) =>
          typeof data[key] === "object" && data[key] !== null ? (
            <>
            <br/>
              <tr>
                <td colSpan={2}><b><h5>{readableString(key)}</h5></b></td>
              </tr>
                <hr />
              <tr>{printObject(data[key])}</tr>
            </>
          ) : (
            <tr key={i}>
              <td colSpan={2}>{readableString(key)}</td>
            </tr>
          )
        )}
    </>
  );
};
const readableString = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
const printObject = (obj) => {
  return Object.keys(obj).map((key, i) => (
    <tr key={i}>
      <td className={`${styles["tdata"]}`}>{readableString(key)}</td>
      {typeof obj[key] !== "object" ? <td className={`${styles["tdata"]}`}><b>{(obj[key]? obj[key] : "-")}</b></td> : ( obj[key]!==null && printObject(obj[key]) )}
    </tr>
  ));
};
const printObjectRow = (obj) => {
  return Object.keys(obj).map((key, i) => (
    <td className={`${styles["tdata"]}`}><b>{(obj[key]? obj[key] : "-")}</b></td>
  ));
}
export default SubscriptionLogs;
