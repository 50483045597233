const initialState = {
  subscriptionDetails: {},
  statusHistorySubsciprion: {},
  transactionDetails: {},
  statusHistoryTransaction: {},
  userDetails: {},
  subscriptionLogs:{},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_SUBSCRIPTION":
      console.log("reducer", action);
      return {
        ...state,
        subscriptionDetails: action.data,
        statusHistorySubsciprion: action.data.statusHistory,
      };
    case "ADD_TRANSACTION":
      return {
        ...state,
        transactionDetails: action.data,
        statusHistoryTransaction: action.data.statusHistory,
      };
    case "ADD_USER_DETAILS":
      return {
        ...state,
        userDetails: action.data
      }
    case "ADD_SUBSCRIPTION_LOGS":
      return{
        ...state,
        subscriptionLogs:action.data
      }
    default:
      return state;
  }
};

export default reducer;
