import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
} from "mdbreact";
import { Redirect, useParams } from "react-router-dom";
import GetUserToken from "../../common/GetUserToken";
import GetTableData from "../../common/GetTableData";
import { currentConfig } from "../aws/AwsConfig";
import Amplify from "aws-amplify";
import API from "@aws-amplify/api";
import Loading from "../../common/Loading";
import Subscription from "./Subscription";
import Transaction from "./Transaction";
import { useDispatch, useSelector } from "react-redux";
Amplify.configure(currentConfig);

const PanelPage = () => {
  const dispatch = useDispatch()
  var { id } = useParams();
  const [userData, setUserData] = React.useState({});
  // eslint-disable-next-line
  const [redirectToLogin, setRedirectToLogin] = React.useState(
    GetUserToken() ? false : true
  );

  const [activeItem, setActiveItem] = React.useState("1");

  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    let dataFromLocalStorage = GetTableData();
    async function getDataApi() {
      setLoading(true);
      const config = {
        response: true,
      };
      await API.get("auctollo-payment-api", "/payment/payments", config)
        .then(function (response) {
          console.log("respponse", response);
          let responseData = response.data.body;
          if (responseData && responseData.data && responseData.data.length) {
            // localStorage.setItem(
            //   "tableData",
            //   JSON.stringify(responseData.data)
            // );
            console.log(responseData);
            // eslint-disable-next-line
            responseData.data.map((dataItem, index) => {
              if (dataItem && dataItem.id.toString() === id) {
                setUserData(dataItem);
              }
            });
          }
        })
        .catch((error) => {
          console.log("er", error);
        });
    }
    if (GetUserToken() && !dataFromLocalStorage && id) {
      getDataApi();
    } else {
      if (id && dataFromLocalStorage && dataFromLocalStorage.length) {
        // eslint-disable-next-line
        dataFromLocalStorage.map((data, index) => {
          if (data && data.id.toString() === id) {
            setUserData(data);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, []);
  if (userData && Object.keys(userData).length) {
    let { id, name, email, registered_at } = userData
    let data = { id, name, email, registered_at }
    dispatch({ type: "ADD_USER_DETAILS", data: data });
  }
  return (
    <React.Fragment>
      {redirectToLogin ? <Redirect to="/login" /> : null}
      {loading ? <Loading /> : null}
      <section className="custom-section">
        {userData && Object.keys(userData).length ? (
          <MDBContainer>
            <MDBCard
              style={{ width: "100%", marginTop: "1rem" }}
              className="site-bg-white"
            >
              <MDBCardHeader color="site-primary-bg-colour" tag="h3">
                Saurabh
              </MDBCardHeader>
              <MDBCardBody>
                <MDBCardText>
                  <span className="font-weight-bold site-medium-text-color">
                    Email :{" "}
                  </span>
                  {userData.email}
                  <br></br>
                  <span className="font-weight-bold site-medium-text-color">
                    Registered At :{" "}
                  </span>
                  {userData.registered_at}
                  {/* table-wrapper-scroll-y */}
                  <MDBNav className="nav-tabs mt-5">
                    <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={activeItem === "1"}
                        role="tab"
                        onClick={() => setActiveItem("1")}
                      >
                        Subscription
                      </MDBNavLink>
                    </MDBNavItem>
                    {/* <MDBNavItem>
                      <MDBNavLink
                        link
                        to="#"
                        active={activeItem === "2"}
                        role="tab"
                        onClick={() => setActiveItem("2")}
                      >
                        Transaction
                      </MDBNavLink>
                    </MDBNavItem> */}
                  </MDBNav>
                  <MDBTabContent activeItem={activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                      <Subscription data={userData} />
                    </MDBTabPane>
                    {/* <MDBTabPane tabId="2" role="tabpanel">
                      <Transaction data={userData} />
                    </MDBTabPane> */}
                  </MDBTabContent>
                  {/* <div className="container">
                  <MDBDataTableV5
                    hover
                    scrollY
                    scrollX={false}
                    data={datatable}
                      paging={false}
                      searching={false}
                      info={false}
                  />
                </div> */}
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBContainer>
        ) : null}
      </section>
    </React.Fragment>
  );
};

export default PanelPage;
