import React, { useState } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTabPane,
  MDBTabContent,
  MDBDataTableV5,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { subscriptionColumn } from "../../constant/TableColums";
import { Redirect } from "react-router-dom";

const Subscription = (props) => {
  const dispatch = useDispatch();
  const [dummySubscription, setDummySubscription] = React.useState({});

  let sr_no = 1;
  const [isModalOpen, setModalOpen] = React.useState(false);
  const hide = () => setModalOpen(false);
  const [activeItem, setActiveItem] = React.useState("1");
  const [redirectToLogs, setRedirectToLogs] = React.useState(null)
  const redirectToSubscriptionLogs = (data) => {
    setRedirectToLogs(true)
    dispatch({ type: "ADD_SUBSCRIPTION", data: data });
  }
  const showModal = (data) => {

    // setModalOpen(!isModalOpen);
    // console.log("damn bpi", data);
    // dispatch({ type: "ADD_SUBSCRIPTION", data: data });
  };
  const subscriptionDetails = useSelector(
    (state) => state.reducer.subscriptionDetails
  );

  const statusHistory = useSelector(
    (state) => state.reducer.statusHistorySubsciprion
  );
  const reducerDAta = useSelector((state) => state.reducer);
  delete subscriptionDetails["transactions"];
  React.useEffect(() => {
    let rows = [];
    let finalDataForTable = {};
    props.data.domains.map((dataItem, index) => {
      const action = [
        <button
          className="btn btn-sm m-0 waves-effect site-primary-dark-colour-outline"
          dataToggle="modal"
          dataTarget="#exampleModal"
          onClick={() => redirectToSubscriptionLogs(dataItem)}
        >
          View all
        </button>,
      ];
      dataItem.additionalData?.subscriptionData?.map((sdata) => {
        sdata.transactions?.map((tData) => {
          rows.push({
            ...dataItem,
            ...sdata,
            ...tData,
            button: action,
            srNo: index + 1,
          });
        });
      });
    });
    finalDataForTable["columns"] = subscriptionColumn;
    finalDataForTable["rows"] = rows;
    setDummySubscription(finalDataForTable);
  }, []);
  console.log('redirectToLogs', redirectToLogs)
  console.log('dummySubscription', dummySubscription)
  return (
    <div className="table-wrapper-scroll-y my-custom-scrollbar">
      {
        redirectToLogs ? (
          <Redirect to='subscriptionlogs' />
        ) : null
      }
      <MDBDataTableV5
        hover
        entriesOptions={[5, 20, 25, 50]}
        entries={5}
        pagesAmount={4}
        data={dummySubscription}
        fullPagination
        searchTop
        searchBottom={false}
        barReverse
        materialSearch
        className="site-form site-table-border-color"
      />
      <Modal
        show={isModalOpen}
        onHide={hide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          marginTop: "50px",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Subscription ID : {subscriptionDetails.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 300px)",
            overflowY: "auto",
          }}
        >
          {/* {console.log(transactionDetails)} */}
          <MDBTable
            className="mdb-table"
            scrollX={true}
            scrollY={false}
            striped
            btn={true}
          >
            <MDBTableBody>
              <MDBNav className="nav-tabs mt-5">
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeItem === "1"}
                    role="tab"
                    onClick={() => setActiveItem("1")}
                  >
                    Subscription Data
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={activeItem === "2"}
                    role="tab"
                    onClick={() => setActiveItem("2")}
                  >
                    Status History
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNav>
              <MDBTabContent activeItem={activeItem}>
                <MDBTabPane tabId="1" role="tabpanel">
                  <Tree data={subscriptionDetails} />
                </MDBTabPane>
                <MDBTabPane tabId="2" role="tabpanel">
                  <Tree data={statusHistory} />
                </MDBTabPane>
              </MDBTabContent>
            </MDBTableBody>
          </MDBTable>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const Tree = ({ data }) => {
  // if (
  //   typeof data !== "undefined" &&
  //   data !== null &&
  //   data.hasOwnProperty("statusHistory")
  // )
  //   delete data["statusHistory"];
  return (
    <>
      {data !== null &&
        typeof data !== "undefined" &&
        Object.keys(data).length > 0 &&
        Object.keys(data).map((key, i) => (
          <>
            {key.toString() != "statusHistory" && (
              <tr>
                <td>
                  {Array.isArray(data)
                    ? Number(Number(key) + 1)
                    : readableString(key)}
                </td>
                <td>{" : "}</td>
                <td>
                  {typeof data[key] === "object" && data[key] !== null ? (
                    <Tree data={data[key]} />
                  ) : data[key] !== null ? (
                    data[key]
                  ) : (
                    <i>null</i>
                  )}
                </td>
              </tr>
            )}
          </>
        ))}
    </>
  );
};
const readableString = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
export default Subscription;
