import React from "react";
import { MDBDataTableV5 } from "mdbreact";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardHeader,
  MDBContainer,
} from "mdbreact";
import { Redirect } from "react-router";
import { UsersViewColums } from "../../constant/TableColums";
import Amplify from "aws-amplify";
import API from "@aws-amplify/api";
import { currentConfig } from "../aws/AwsConfig";
import GetUserToken from "../../common/GetUserToken";
import Loading from "../../common/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datepicker-style.module.css";
import arrow from '../../assets/img/arrow.svg'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
Amplify.configure(currentConfig);

export default function UserList() {
  const [datatable, setDatatable] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [selectedDomain, setDomain] = React.useState('')
  const [endDate, setEndDate] = React.useState(moment());
  const [startDate, setStartDate] = React.useState(
    moment().subtract(90, "days")
  );
  const [selectedType, setSelectedType] = React.useState("");

  const [userDetails, setUserDetails] = React.useState()
  const userData = useSelector( (state) => state.reducer.userDetails)
  // eslint-disable-next-line
  const [redirectToLogin, setRedirectToLogin] = React.useState(
    GetUserToken() ? false : true
  );
  const dispatch = useDispatch()
  const editUserData = (e) => {
    // console.log('name',JSON.parse(e.target.name))
    if (e.target.id) {
      setUserDetails(JSON.parse(e.target.name))
      setDomain(e.target.id)
      setRedirectToUserData(e.target.id);
    }
  };
  const statusType = [
    "Active",
    "Canceled",
    "Submitted for settlement"
  ];
  const onSelectStartDate = (startdate) => {
    setStartDate(startdate);
  };
  const onSelectEndDate = (enddate) => {
    setEndDate(enddate);
  };
  const handleChange = (e) => {
    setSelectedType(e.target.value);
  };
  const getPaymentApi = async(config) =>{
    let rows = [];
    let finalDataForTable = {};
    await API.get("auctollo-payment-api", "/payment/payments", config)
        .then(function (response) {
          let responseData = JSON.parse(response.body);
          if (responseData && responseData.data && responseData.data.length) {
            localStorage.setItem(
              "tableData",
              JSON.stringify(responseData.data)
            );
            // eslint-disable-next-line
            const key = 'domain'
            responseData.data[0].domains = [...new Map(responseData.data[0].domains.map(item =>[item[key], item])).values()];
            localStorage.setItem("domains",JSON.stringify(responseData.data[0].domains))
            responseData.data.map((dataItem, index) => {
              
              dataItem.domains.map(domainItem=>{
                const action = [
                  <button
                    key={dataItem.id}
                    id={domainItem.domain}
                    name={JSON.stringify({...dataItem,domainItem})}
                    type="button"
                    onClick={(e) => editUserData(e)}
                    className="btn btn-sm m-0 waves-effect site-primary-dark-colour-outline"
                  >
                    View
                  </button>,
                ];
                const amount = dataItem.domains.map(item=>{return (item.domain === domainItem.domain && item.amount_paid ) })
                const planType = dataItem.domains.map(item=>{return (item.domain === domainItem.domain && item.plan ) })

                const price = amount.filter(amt=>amt)
                const plan = planType.filter(pln=>pln)
                rows.push({ ...dataItem, button: action, srNo: index + 1, 
                  domain:domainItem.domain,//dataItem.domains[0].domain, 
                  status:domainItem.status,//dataItem.domains[0].status,
                  amount:price,
                  plan:plan,
                  // s3RootUrl: dataItem.domains[0].s3RootUrl,
                  // Amount
                  
                  subscriptionId: domainItem.subscription_id });// dataItem.domains[0].subscription_id  });
                })
            });
            finalDataForTable["columns"] = UsersViewColums;
            finalDataForTable["rows"] = rows;
            setDatatable(finalDataForTable);
            dispatch({type:"ADD_USER_DETAILS",data:finalDataForTable})
            setLoading(false);
          }
          if(responseData.data.length ===0){
    
            finalDataForTable["columns"] = UsersViewColums;
              finalDataForTable["rows"] = [];
              setDatatable(finalDataForTable);
              dispatch({type:"ADD_USER_DETAILS",data:finalDataForTable})
              setLoading(false);
          }
        })
        .catch((error) => {
          console.log("er", error);
        });
  }
  React.useEffect(()=>{
    const tempTableData = Object.assign({}, datatable);
    if (tempTableData?.rows) {
      // setLoading(true);
      let tdata = tempTableData;
      // tdata.rows = tdata.rows.filter(
      //   (item) =>
      //     moment(new Date(item.registered_at).toISOString()) >= startDate && moment(new Date(item.registered_at).toISOString()) <= endDate
      // );
      // setTimeout(() => setLoading(false), 2000);
      dispatch({ type: "ADD_USER_DETAILS", data: tdata });
      const config = {
        queryStringParameters: {
          body :JSON.stringify({
            startDate,
            endDate,
            status:selectedType
          })
        },
      }
      getPaymentApi(config)
      // if(selectedType!==""){
      //   tdata.rows = tdata.rows.filter(
      //       (item) =>
      //         item.status.includes(selectedType)
      //     );
      //     dispatch({ type: "ADD_USER_DETAILS", data: tdata });
  
      //     setLoading(false);
      // }
    }
  },[startDate,endDate])
  React.useEffect(()=>{
    const tempTableData = Object.assign({}, datatable);
    if (tempTableData?.rows && selectedType!=='') {
      let tdata = tempTableData;
      const config = {
        queryStringParameters: {
          body :JSON.stringify({
            startDate,
            endDate,
            status:selectedType
          })
        },
      }
      getPaymentApi(config)

    }
  },[selectedType])
  const reset = () => {
    setSelectedType("");
    setEndDate(moment());
    setStartDate(moment().subtract(90, "days"));
    let search = document.getElementsByClassName('form-control-sm')[0]
  };
  React.useEffect(() => {
    let rows = [];
    let finalDataForTable = {};
    async function getDataApi() {
      setLoading(true);
      const config = {
        response: true,
      };
      await API.get("auctollo-payment-api", "/payment/payments", config)
        .then(function (response) {
          let responseData = JSON.parse(response.data.body);
          // console.log("ressponse", responseData);
          if (responseData && responseData.data && responseData.data.length) {
            localStorage.setItem(
              "tableData",
              JSON.stringify(responseData.data)
            );
            // eslint-disable-next-line
            const key = 'domain'
            responseData.data[0].domains = [...new Map(responseData.data[0].domains.map(item =>[item[key], item])).values()];
            localStorage.setItem("domains",JSON.stringify(responseData.data[0].domains))
            responseData.data.map((dataItem, index) => {
              
              dataItem.domains.map(domainItem=>{
                const action = [
                  <button
                    key={dataItem.id}
                    id={domainItem.domain}
                    name={JSON.stringify({...dataItem,domainItem})}
                    type="button"
                    onClick={(e) => editUserData(e)}
                    className="btn btn-sm m-0 waves-effect site-primary-dark-colour-outline"
                  >
                    View
                  </button>,
                ];
                const amount = dataItem.domains.map(item=>{return (item.domain === domainItem.domain && item.amount_paid ) })
                const planType = dataItem.domains.map(item=>{return (item.domain === domainItem.domain && item.plan ) })

                const price = amount.filter(amt=>amt)
                const plan = planType.filter(pln=>pln)
                rows.push({ ...dataItem, button: action, srNo: index + 1, 
                  domain:domainItem.domain,//dataItem.domains[0].domain, 
                  status:domainItem.status,//dataItem.domains[0].status,
                  amount:price,
                  plan:plan,
                  // s3RootUrl: dataItem.domains[0].s3RootUrl,
                  // Amount
                  
                  subscriptionId: domainItem.subscription_id });// dataItem.domains[0].subscription_id  });
                })
            });
            finalDataForTable["columns"] = UsersViewColums;
            finalDataForTable["rows"] = rows;
            setDatatable(finalDataForTable);
            dispatch({type:"ADD_USER_DETAILS",data:finalDataForTable})
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("er", error);
        });
    }
    getDataApi();
  }, []);

  const [redirectToUserData, setRedirectToUserData] = React.useState();
  return (
    <React.Fragment>
      {redirectToLogin ? <Redirect to="/login" /> : null}
      {redirectToUserData ? (
        <Redirect to={{pathname:"/user/subscriptionlogs",state:{domain:selectedDomain,userDetails:userDetails}}} />
      ) : null}
      {loading ? <Loading /> : null}
      {datatable ? (
        <MDBContainer>
        <MDBCard
          style={{ width: "100%", marginTop: "5rem" }}
          className="site-bg-white"
        >
        <MDBCardHeader color="site-primary-bg-colour" tag="h3">
          Users List
        </MDBCardHeader>
        <section className="custom-section">
          <div className="container site-bg-white">
          <div className={`${styles["datepickerWrapper"]}`}>
          &nbsp;&nbsp;<Typography variant="subtitle1">Date range </Typography> : &nbsp;&nbsp;

                  <div className={`${styles["datewrapper"]}`} >
                    <DatePicker
                      onChange={(date) => onSelectStartDate(date)}
                      openToDate={new Date(startDate)}
                      value={moment(startDate).format("MMM DD, YYYY")}
                      startDate={startDate}
                      endDate={endDate}
                      // calendarIcon="Calendar"
                      // selectsStart
                      dateFormat="MMM DD, YYYY"
                      dropdownMode="select"
                      adjustDateOnChange
                      className={`${styles["searchinput"]}`}
                    />
                    </div>
                    <div className={`${styles["arrow"]}`}>
                        <img src={arrow} />
                    </div>
                  <div className={`${styles["datewrapper"]}`}>
                    <DatePicker
                      onChange={(date) => onSelectEndDate(date)}
                      // onSelect={date=>this.onSelectEndDate(date)}
                      openToDate={new Date(endDate)}
                      value={moment(endDate).format("MMM DD, YYYY")}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      calendarIcon
                      // selectsEnd
                      dateFormat="MMM DD, YYYY"
                      dropdownMode="select"
                      adjustDateOnChange
                      className={`${styles["searchinput"]}`}
                      
                    />
                  </div>
                  &nbsp;&nbsp;<Typography variant="subtitle1">Status </Typography> : &nbsp;&nbsp;
                    <div className={`${styles["datewrapper"]}`}>
                      <FormControl
                        // variant="filled"
                        size="small"
                        sx={{
                          m: 1,
                          minWidth: 250,
                          // marginBottom: "20px",
                          marginLeft: "20px",
                        }}
                      >
                        
                        {/* <InputLabel id="demo-simple-select-standard-label">
                          Subscription Event
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectedType}
                      autoWidth

                          onChange={handleChange}
                          // label="Subscription Event"
                        >
                      
                          {statusType.map((item,i) => (
                            <MenuItem key={i} value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={`${styles["datepickerWrapper"]}`}>
                  <Button variant="outlined" className={`${styles["resetbutton"]}`} onClick={reset} size="large">
                    Reset
                  </Button>
                  </div>
          </div>
            <MDBDataTableV5
              hover
              entriesOptions={[5, 20, 25, 50]}
              entries={5}
              pagesAmount={4}
              data={userData}
              fullPagination
              searchTop
              searchBottom={false}
              barReverse
              materialSearch
              className="site-form site-table-border-color"
            />
          </div>
        </section>
        </MDBCard></MDBContainer>

      ) : null}

    </React.Fragment>
  );
}
